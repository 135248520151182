import React, { useState, useCallback, useEffect } from 'react';
import PubSub from 'pubsub-js';
import Filter from './Filter';
import FilterRegions from './FilterRegions';
import {
  recursiveAddKeyVal,
  recursiveExtractByKey,
  recursiveExtractByLabel,
} from '../core/utils';
import { useApolloClient, gql } from '@apollo/client';

function loadFiltersTreeData(items) {
  const dataMap = new Map();

  while (true) {
    const filter = items.shift();
    if (!filter) {
      break;
    }

    let node = dataMap.get(filter.id);
    if (!node) {
      node = {
        id: filter.id,
        label: filter.label,
        labelLong: filter.label,
        value: filter.value,
        icon: filter.icon,
        color: filter.color,
        hidden: filter.hidden,
        ordering: filter.ordering,
      };

      dataMap.set(filter.id, node);
    }

    if (filter.parentId) {
      const parent = dataMap.get(filter.parentId);
      if (!parent) {
        items.push(filter);
        continue;
      }

      node.labelLong = `${parent.labelLong} - ${node.label}`;
      node.value = `${parent.value}|${node.value}`;
      node.hidden = node.hidden || parent.hidden;
      node.color = node.color || parent.color;
      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(node);
    }
  }

  const data = Array.from(dataMap.values()).filter(
    (filter) => !filter.value.includes('|')
  );

  data.sort((itemA, itemB) =>
    itemA.ordering === itemB.ordering
      ? itemA.value.localeCompare(itemB.value)
      : itemA.ordering - itemB.ordering
  );

  return data;
}

function useLoadFilters() {
  const client = useApolloClient();
  if (!client) {
    throw new Error('Apollo client is undefined');
  }
  const variables = {
    // offset: 0,
    // limit: 10,
    // filter: { parentId: null },
  };
  return async () => {
    const result = await client.query({
      query: gql`
        query filters {
          filters {
            id
            label
            value
            hidden
            ordering
            color
            icon
            parentId
          }
        }
      `,
      variables,
    });

    if (result?.data?.filters === undefined) {
      throw new Error('No data');
    }

    return loadFiltersTreeData([...result.data.filters]);
  };
}

function sortByType(data) {
  let dataSorted = {};

  const keys = ['Donnees', 'Sujet', 'Series'];
  keys.forEach((key) => {
    const keyClean = key.replace('-', '_').toLowerCase();
    dataSorted[keyClean] = data.filter((el) => el.label === key);
  });

  /**
   * Regions
   */
  const regionsRaw = data.filter((el) => el.value === 'regions-naturelles');
  recursiveAddKeyVal(regionsRaw, 'isRegion', true);
  dataSorted['regions_naturelles'] = regionsRaw;

  /**
   * FORM
   */
  const formes = recursiveExtractByLabel(data, 'Formes');
  if (!formes[0].hidden) dataSorted['formes'] = formes;

  /**
   * COLOR
   */
  const couleurs = recursiveExtractByLabel(data, 'Couleurs');
  if (!couleurs[0].hidden) dataSorted['couleurs'] = couleurs;

  /**
   * LEFTOVER
   */
  const leftover = data.filter(
    (el) =>
      el.label !== 'Donnees' &&
      el.label !== 'Sujet' &&
      el.label !== 'Series' &&
      el.label !== 'Regions-naturelles' &&
      el.label !== '- sélections (travail personnel en cours)' &&
      el.label !== '09 - sélections (perso)'
  );

  let themes = leftover.map((el) => {
    if (el.label === 'Description') {
      return el;
    } else {
      return el;
    }
  });

  /**
   * EXTRACT MATERIAUX
   */
  const materiaux = recursiveExtractByLabel(data, 'Matériaux');
  materiaux.forEach((el) => themes.push(el));

  dataSorted['themes'] = themes;
  /**
   * REORDER THEMES
   */
  dataSorted['themes'][0].children.sort((a, b) => {
    return a.ordering - b.ordering;
  });
  dataSorted['sujet'][0].children.sort((a, b) => {
    return a.ordering - b.ordering;
  });

  const hasIcon = recursiveExtractByKey(data, 'icon');
  dataSorted['withIcon'] = hasIcon;

  /**
   * REORDER Series alpha
   */
  dataSorted['series'][0].children.sort((a, b) =>
    ('' + a.label).localeCompare(b.label)
  );

  return dataSorted;
}

function useFilters() {
  const [filtersData, setFiltersData] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const loadFilters = useLoadFilters();

  const fetchFiltersToTreeData = useCallback(async () => {
    try {
      setLoading(true);

      /**
       * SESSION STORAGE (CACHE)
       */
      let data = {};
      if (sessionStorage.getItem('data')) {
        data = JSON.parse(sessionStorage.getItem('data'));
      } else {
        data = await loadFilters();
        sessionStorage.setItem('data', JSON.stringify(data));
      }

      setFiltersData(sortByType(data));

      setError(undefined);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [setLoading, setError]);

  useEffect(() => {
    if (!filtersData) {
      fetchFiltersToTreeData();
    }
  }, [filtersData, fetchFiltersToTreeData]);

  return {
    isLoading,
    error,
    filters: filtersData,
  };
}

const Loading = () => <div>Loading...</div>;
const Failure = ({ error }) => {
  // console.log(error);
  return <div>Error loading filters: {error.message}</div>;
};

function FilterTree({ filters, ...props }) {
  const depth = 0;
  return (
    <ul>
      {filters.map((filter) => (
        <Filter
          key={`filter-${filter.value}`}
          filter={filter}
          {...props}
          depth={depth}
        />
      ))}
    </ul>
  );
}

function FiltersSummaryDetail({ title, filters, ...props }) {
  return (
    <details>
      <summary className="strong curp">{title}</summary>
      <FilterTree filters={filters} {...props} />
    </details>
  );
}

function FiltersModal({ title, filters, ...props }) {
  const [active, setActive] = useState(false);
  useEffect(() => {
    PubSub.publish('FILTERS_REGIONS_ALL', filters);
  });
  const depth = 0;
  return (
    <div className={`filters-modal ${active ? 'is-active' : ''}`}>
      <div className="title strong curp ttu" onClick={() => setActive(!active)}>
        {title}
      </div>
      <ul>
        {filters.map((filter) => (
          <FilterRegions
            key={`filter-${filter.value}`}
            filter={filter}
            {...props}
            depth={depth}
          />
        ))}
      </ul>
    </div>
  );
}

export function Filters(props) {
  const { isLoading, error, filters } = useFilters();

  return (
    <>
      {!isLoading && error && <Failure error={error} />}
      {isLoading && <Loading />}

      {!isLoading && !error && filters && (
        <div className="filters-wrap x h100">
          {filters !== undefined && (
            <>
              <div className="c3 ">
                <div className="regions filter-group">
                  <FiltersModal
                    title={'Index des régions'}
                    filters={filters.regions_naturelles}
                    {...props}
                  />
                </div>

                {filters.formes && (
                  <div className="formes filter-group">
                    <FiltersSummaryDetail
                      title={'FORMES'}
                      filters={filters.formes}
                      {...props}
                    />
                  </div>
                )}

                {filters.couleurs && (
                  <div className="couleurs filter-group">
                    <FiltersSummaryDetail
                      title={'COULEUR'}
                      filters={filters.couleurs}
                      {...props}
                    />
                  </div>
                )}

                {filters.withIcon && (
                  <div className="with-icon filter-group">
                    <FiltersSummaryDetail
                      title={'PICTOGRAMMES'}
                      filters={filters.withIcon}
                      {...props}
                    />
                  </div>
                )}

                <div className="series filter-group">
                  <FiltersSummaryDetail
                    title={'SÉRIES'}
                    filters={filters.series}
                    {...props}
                  />
                </div>
              </div>

              <div className="c9 ">
                <div className="donnees filter-group">
                  <FilterTree filters={filters.donnees} {...props} />
                </div>
                <div className="wrap">
                  <div className="column column-3">
                    <div className="themes filter-group">
                      <FilterTree filters={filters.themes} {...props} />
                    </div>
                    <div className="sujet filter-group">
                      <FilterTree
                        filters={filters.sujet}
                        sort="ordering"
                        {...props}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
