import React, { useState, useContext, useEffect } from 'react';
import { FiltersContext } from './Layout'
import { navigate } from 'gatsby';
import { dynamicSort } from '../core/utils'
// import FilterTree from './FilterTree'

const Filter = ({ filter, ...props }) => {
  const _FiltersContext = useContext(FiltersContext)
  const { filters, dispatch } = _FiltersContext
  // console.log("depth",props.depth)
  const depth = props.depth + 1
  const [checked, setChecked] = useState(undefined);

  function handleChange(event) {
    setChecked(event.target.checked);
  }

  useEffect(() => {
    if (checked === undefined) {
      return;
    }
    // console.log("Filter",checked)
    if (checked) {
      // props.addFilter(filter.id);
      dispatch({ type: "ADD", payload: filter })
    } else {
      // props.removeFilter(filter.id);
      // dispatch("REMOVE", filter.id)
      console.log("remove ", filter)
      dispatch({ type: "REMOVE", payload: filter })
    }
  }, [checked]);

  const isActive = () => {
    return filters.find(f => f.id === filter.id);
  }

  const _submit = () => {
    navigate("/liste")
  }

  const renderFilterIcon = () => (
    <div className="filter-icon curp">
      <img
        src={filter.icon}
        style={{ height: '15px' }}
        alt={filter.label}
      />
    </div>
  )

  const renderFilterDefault = () => <span>{filter.label}</span>

  const className = isActive() ? 'is-active' : ''
  const showSubmit = isActive() && filters.length > 0
  // console.log(filter.label)
  // if (filter.label === "Viticulture") console.log(filter)

  return filter.hidden ? null : (
    // return (
    <li className={`filter ${className} depth-${props.depth}`} id={filter.label.trim().toLowerCase()}>
      <label
        //style={{ color: filter.color }} 
        title={filter.labelLong}>
        <input
          name="filters[]"
          type="checkbox"
          value={filter.value}
          checked={checked || false}
          onChange={handleChange}
        ></input>

        {filter.icon
          ? renderFilterIcon()
          : renderFilterDefault()}
      </label>

      {showSubmit && <span className="submit curp" onClick={_submit}>→</span>}

      {filter.children && filter.children.length > 0 &&
        <FilterTree filters={filter.children} {...props} depth={depth} />
      }
    </li>

  );
};

function FilterTree({ filters, ...props }) {
  // console.log(filters)
  return (
    <ul>
      {filters.map((filter) => (
        <Filter key={`filter-${filter.value}`} filter={filter} {...props} />
      ))}
    </ul>
  );
}

export default Filter;