import React, { useEffect, useRef, useState } from 'react';
import MapFile from '../images/map-arn.inline.svg'
// import { flattenObject } from '../core/utils'

const Map = () => {
  const [zonesLabel, setZonesLabel] = useState([])
  const mapRef = useRef();
  const tooltipRef = useRef();
  
  useEffect(() => {
    _setListeners()

    // const token = PubSub.subscribe("REGION.FILTER", (e,d) => {
    //   if(!d.id)return
      
    //   const zone = mapRef.current.querySelector(`#${d.id}`)
    //   if(zone){
    //     if(d.hover){
    //       zone.classList.add("is-hover")
    //     }else if(d.click){
    //       zone.classList.add("is-active")
    //     }else{
    //       zone.classList.remove("is-hover")
    //       zone.classList.remove("is-active")
    //     }
    //   }
    // })

    return () => {
      _resetListeners()
      // PubSub.unsubscribe(token)
    }
  }, [zonesLabel])

  const _setListeners = () => {
    PubSub.subscribe("FILTERS_REGIONS_ALL", _serializeZone)
    mapRef.current?.querySelectorAll("g > *").forEach((element, i) => {
      // console.log(element.id)
      if(!element.id){
        const id = element.parentNode.id
        element.dataset.name = id
        // console.log(id)
      }
      element.addEventListener("mouseenter", _onMouseEnter)
      element.addEventListener("mouseleave", _onMouseLeave)
      element.addEventListener("click", _onClick)
      element.style.stroke = "#121212"
      // setTimeout(() => {
      //   element.style.stroke = "#121212"
      // }, (i*10))
    });
  }

  const _resetListeners = () => {
    PubSub.unsubscribe("FILTERS_REGIONS_ALL", _serializeZone)

    mapRef.current?.querySelectorAll("g > *").forEach(element => {
      // if(l)
      element.removeEventListener("mouseenter", _onMouseEnter)
      element.removeEventListener("mouseleave", _onMouseLeave)
      element.removeEventListener("click", _onClick)
    });
  }

  const _serializeZone = (e,d) => {
    const flattened = flatten(d[0].children);
    setZonesLabel(flattened)
    // console.log(flattened)
  }

  function flatten(arr) {
    let result = [];
    arr.forEach(a => {
      if (Array.isArray(a.children)) {
        a.children.forEach(b => {
          const label = b.label.trim()
          const tmpValue = b.value.split("|")
          const value = tmpValue[tmpValue.length -1]
          result[value] = label
        })
      }
    });
    return result;
  }

  const _onMouseEnter = (e) => {
    // console.log(e)
    // e.target.style.fill = "red"
    const id = e.target.dataset.name || e.target.id
    if(!id)return
    // tooltipRef.current.classList.remove("text-right")
    /**
     * update based on position on map 
     */
    let isRight = false
    const {width, top, left} = mapRef.current.getBoundingClientRect()
    const bounding = e.target.getBoundingClientRect()
    // let {x, y} = bounding
    // console.log(left, width, bounding.x)
    let x = bounding.x
    let y = bounding.y - top - 10
    
    // console.log(left, x, width)
    if(x > width/2){
      // x = width - (bounding.x + left)
      x = width - bounding.x
      isRight = true
    }else{
      x = x - left
    }

    // console.log(bounding)
    _updateToolTip(id, e.layerX, y, isRight)
    // _updateToolTip(id, e.layerX, e.layerY)
    
    const isActive = e.target.classList.contains("is-active")
    PubSub.publish("REGION.ZONE", {
      hover: true,
      active: isActive,
      id: id
      // id: _serializeId(e.target.id)
    })
  }

  const _onMouseLeave = (e) => {
    // console.log(e.target.id)
    // e.target.style.fill = "none"
    const id = e.target.dataset.name || e.target.id
    const isActive = e.target.classList.contains("is-active")
    PubSub.publish("REGION.ZONE", {
      hover: false,
      active: isActive,
      id: id
      // id: _serializeId(e.target.id)
    })
  }

  const _updateToolTip = (id, x, y, isRight) => {
    id = _serializeId(id)
    let label = zonesLabel[id]
    // console.log(id, label)
    
    if(!label)label = id
    tooltipRef.current.innerText = label
    if(isRight){
      // tooltipRef.current.style.left = "unset"
      const {width} = tooltipRef.current.getBoundingClientRect()
      tooltipRef.current.style.left = `${x - width}px`
    }else{
      // tooltipRef.current.style.right = "unset"
      tooltipRef.current.style.left = x+"px"
    }
    
    tooltipRef.current.style.top = y+"px"
  }

  const _onClick = (e) => {
    // console.log(e)
    let id = e.target.dataset.name || e.target.id
    if(!id)return

    const isActive = e.target.classList.contains("is-active") || e.target.parentNode.classList.contains("is-active")
    if(!isActive)_unSelectOthers()
    // console.log(isActive)
    // return
    PubSub.publish("REGION.ZONE", {
      hover: true,
      active: !isActive,//toggle active
      id: id
      // id: _serializeId(e.target.id)
    })
  }

  const _unSelectOthers = () => {
    mapRef.current.querySelectorAll(".is-active").forEach(el => {
      // console.log(element)
      // element.click()
      PubSub.publish("REGION.ZONE", {
        hover: false,
        active: false,//toggle active
        id: el.id
        // id: _serializeId(e.target.id)
      })
    })
  }

  const _serializeId = (id) => {
    // if(id.match(/\d+/g) != null){
    // console.log(id.match(/\D\d+$/g))
    if(id.match(/\D\d+$/g) != null){
      id = id.replace(/\D\d+$/g, "")
    }
    // console.log(id)
    return id
  }

  return (
    <div id="map" ref={mapRef}>
      <div className="inner">
        <MapFile />
        <div className="tooltip outline" ref={tooltipRef}></div>
      </div>
    </div>
    
  );
};

export default Map;