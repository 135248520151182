import React, { useState, useContext, useEffect } from 'react';
import { FiltersContext } from './Layout';
import PubSub from 'pubsub-js';
import { checkPropTypes } from 'prop-types';
import { dynamicSort } from '../core/utils';

const FilterTree = ({ filters, ...props }) => {
  // console.log("FilterRegions", filters.length)
  // console.log(filters)
  filters.sort(dynamicSort('label'));

  return (
    <ul>
      {filters.map((filter) => (
        <FilterRegions
          key={`filter-${filter.value}`}
          filter={filter}
          {...props}
        />
      ))}
    </ul>
  );
};

const FilterRegions = ({ filter, ...props }) => {
  const parts = filter.value.split('|');
  const id = parts[parts.length - 1];

  const _FiltersContext = useContext(FiltersContext);
  const { filters, dispatch } = _FiltersContext;
  // console.log(filters, filter)
  const initialActive = filters.filter(el => el.id === filter.id).length > 0
  // console.log(initialActive)
  // if(initialActive)console.log(filter.label)
  const [active, setActive] = useState(initialActive);
  const [hover, setHover] = useState(false);

  // const id = filter.label.trim().toLowerCase()
  

  const hasChildren = filter.children && filter.children.length > 0;
  const depth = props.depth + 1;

  // useEffect(() => {
  //   _getActiveState()
  // }, [])

  useEffect(() => {
    // console.log(hover)
    if (hover) {
      document.getElementById(id).classList.add('is-hover');
    } else {
      document.getElementById(id).classList.remove('is-hover');
    }
  }, [hover]);

  useEffect(() => {
    // if (active === undefined)return
    // return
    // console.log(active)
    // console.log("useEffect active")
    if (active) {
      // console.log(active)
      _removeOthers()
      setTimeout(() => {
        // console.log("add", filter)
        // console.log(active, filter.label)
        dispatch({ type: 'ADD', payload: filter });
        document.getElementById(id).classList.add('is-active');
      }, 150)
      
    } else {      
      dispatch({ type: 'REMOVE', payload: filter });
      document.getElementById(id).classList.remove('is-active');
    }
  }, [active]);

  const _getActiveState = () => {
    // const id = filter.id
    // console.log(id)
    const exists = filters.filter(el => el.id === id)
    return exists.length > 0
    // console.log(filter.label, exists)
  }
  // setActive(_getActiveState())

  const _removeOthers = () => {
    // return
    const regions = filters.filter(el => el.isRegion)
    if(regions.length === 0)return

    regions.forEach(region => {
      const _parts = region.value.split('|');
      const _id = _parts[_parts.length - 1];
      
      PubSub.publish("REGION.ZONE", {
        hover: false,
        active: false,
        id: _id
      })
    })
    
    
    // dispatch({ type: 'REMOVE', payload: region[0] });
  }

  useEffect(() => {
    //Event coming from map svg
    const token = PubSub.subscribe('REGION.ZONE', (e, d) => {
      // console.log(d.id, id)
      if (d.id === id) {
        // console.log(d)
        if (!d.hover && !d.active) {
          setHover(false);
          setActive(false);
        } else {
          setHover(d.hover);
          setActive(d.active);
        }
      }
    });

    return () => PubSub.unsubscribe(token);
  }, [setHover, setActive]);

  const _onMouseEnter = () => {
    if (hasChildren) return;
    setHover(true);
  };

  const _onMouseLeave = () => {
    if (hasChildren) return;
    setHover(false);
  };

  const _onChange = (event) => {
    // console.log("_onChange", event.target)
    // setActive(event.target.checked)
    const checked = event.target.checked
    setActive(checked)
    // console.log(event.target)
    // if(checked){
    //   _uncheckAll(() => setActive(true))
    // }else{
    //   console.log("unseelected", event.target.value)
    //   setActive(false)
    // }
  };

  // const _update = (value) => 

  const _uncheckAll = (cb) => {
    console.log("_uncheckAll")
    document.querySelector(".filters-modal").querySelectorAll("input:checked").forEach(el => {
      // const event = document.createEvent("HTMLEvents");
      // event.initEvent('change', false, false);
      // console.log(event)
      // el.dispatchEvent(event);
      const event = new Event('change');
      el.checked = false
      el.dispatchEvent(event);

      // el.checked = false;
      // console.log(el, el.checked)
    })
    setTimeout(() => cb(), 1000)
  }

  return (
    <li
      className={`filter ${hover ? 'is-hover' : ''} ${
        active ? 'is-active' : ''
      } ${hasChildren ? 'has-children' : ''} depth-${props.depth}`}
      id={id}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    >
      <label
        //style={{ color: filter.color }}
        title={filter.labelLong}
      >
        <input
          name="filters[]"
          type="checkbox"
          value={filter.value}
          checked={active || false}
          onChange={_onChange}
        ></input>
        {filter.icon ? (
          <img
            src={filter.icon}
            style={{ height: '30px' }}
            alt={filter.label}
          />
        ) : (
          <span>{filter.label}</span>
        )}
      </label>
      {hasChildren && (
        <FilterTree filters={filter.children} {...props} depth={depth} />
      )}
    </li>
  );
};

export default FilterRegions;
