import React from 'react';
import SEO from "../components/seo"
import { Filters } from '../components/Filters';
import Map from '../components/Map'


const Home = () => {

  return (
    <div className="home">
      <SEO
        pageTitle="Accueil"
        pageDescription=""
        pageImage=""
        page={false}
        template="template-home"
      />

      <div className="content">
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <Map filters={""} />
          </div>
          <div className="col-md-6 col-xs-12">
              <Filters />
          </div>
        </div>

      </div>
    </div>
  );
};

export default Home;
